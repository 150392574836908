import { createStore } from "vuex";
import authService from "@/services/auth";
import devicesService from "@/services/device";
import sitesService from "@/services/site";
import appsLibraryService from "@/services/appLibrary";
import gridService from "@/services/grid";
import areaService from "@/services/area";
import userService from "@/services/user";
import companyService from "@/services/company";
import inboxService from "@/services/inbox";
import jobService from "@/services/jobs";
import folderService from "@/services/folders";

import configurationService from "@/services/configuration";

import { useToast } from "vue-toastification";    
import useEncryption from "@/utils/helpers/encryption";
const { encryptionPassword } = useEncryption();

const toast = useToast();
import router from "@/router";

const store = createStore({
    state:{
      userPermissions: [],
      loading: false,
      tableHeaders: {},
      tableFilters: {},
      adminPreferenceId: null,
      companyId: null,
      userDetails : {},
      modalState : false,
      modalData : null,
      modalAction : null,
      deviceDataObject : null,
      remoteControlObject : null,
      currentCompany : {},
      isCollapsed: false
    },
    getters:{
      getLoading(state) {
        return state.loading;
      },
      getUserPermissions(state) {
        return state.userPermissions;
      },
      getTableHeaders(state) {
        return state.tableHeaders;
      },
      getTableFilters(state) {
        return state.tableFilters;
      },
      getAdminPreferenceId(state) {
        return state.adminPreferenceId;
      },
      getCurrentCompanyId(state) {
        return state.companyId;
      },
      getUserDetails(state) {
        return state.userDetails
      },
      getModalInfo(state) {
        return state.modalData;
      },
      getModalState(state) {
        return state.modalState;
      },
      getModalAction(state) {
        return state.modalAction;
      },
      getDeviceDataObject(state) {
        return state.deviceDataObject;
      },
      getRemoteControlDataObject(state) {
        return state.remoteControlObject;
      },
      getCurrentCompany(state) {
        return state.currentCompany;
      },
      getCollapsedState(state){
        return state.isCollapsed
      }
    },
    mutations:{
      setLoading(state, payload) {
        state.loading = payload;
      },
      setUserPermissions(state, payload) {
        state.userPermissions = payload;
      },
      setTableHeaders(state, payload) {
        state.tableHeaders = payload;
      },
      setTableFilters(state, payload) {
        state.tableFilters = payload;
      },
      setAdminPreferenceId(state, payload) {
        state.adminPreferenceId = payload;
      },
      setCurrentCompanyId(state, payload) {
        state.companyId = payload;
      },
      setUserDetails(state, payload) {
        state.userDetails = payload;
      },
      setModalData(state, payload) {
        state.modalData = payload;
      },
      setModalState(state, payload) {
        state.modalState = payload;
      },
      setModalAction(state, payload) {
        state.modalAction = payload;
      },
      setDeviceDataObject(state, payload) {
        state.deviceDataObject = payload;
      },
      setRemoteControlDataObject(state, payload) {
        state.remoteControlObject = payload;
      },
      setCurrentCompany(state, payload) {
        state.currentCompany = payload;
      },
      resetModalStore(state) {
        state.modalData = null;
        state.modalState = null;
        state.modalAction = null;
      },
      setCollapseSate(state,payload){
        state.isCollapsed = payload
      }
    },
    actions:{
      register({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          authService
            .register(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.data.message);
              router.push('/login')
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.response.data.message);
            });
        });
      },
      async login({ commit }, payload) {
        commit("setLoading", true);
        payload.password = await encryptionPassword(payload.password)
        return new Promise((resolve, reject) => {
          commit("setLoading", true);
          authService
            .login(payload)
            .then(async (data) =>  {
              await localStorage.setItem("user", JSON.stringify(data.data.data));
              await localStorage.setItem("userDetails", JSON.stringify(data.data.data.user.companies[0]?.pivot));
              await localStorage.setItem('currentCompany', JSON.stringify(data.data.data.user.companies[0]));
              await localStorage.setItem('currentCompanyId', data.data.data.user.companies[0]?.id);
              await localStorage.setItem('userCompanies', JSON.stringify(data.data.data.user.companies));
              await commit("setLoading", false);
              await commit("setCurrentCompany", data.data.data.user.companies[0]);
              await commit("setCurrentCompanyId", data.data.data.user.companies[0]?.id);
              await commit("setUserDetails", JSON.stringify(data.data.data.user));
              await commit("setUserPermissions", JSON.stringify(data.data.data.user.role?.permissions));
              resolve(data);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.response.data.message);
            });
        });
      },
      forgetPassword({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          authService
            .forgetPassword(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.data.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.response.data.message);
            });
        });
      },
      async resetPassword({ commit }, payload) {
        commit("setLoading", true);
        payload.password = await encryptionPassword(payload.password);
        payload.code = await encryptionPassword(payload.code);
        return new Promise((resolve, reject) => {
          authService
            .resetPassword(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.data.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.response.data.message);
            });
        });
      },
      async checkUserPassword({ commit }, payload) {
        commit("setLoading", true);
        payload.password = await encryptionPassword(payload.password);
        return new Promise((resolve, reject) => {
          authService
            .checkUserPassword(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.data.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.data.message);
            });
        });
      },
      logout(){
        localStorage.clear();
        // toast.success('logged out successfully');
        router.push('/login');
      },
      fetchDevicesLogs({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getDevicesLogs(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchDeviceLogs({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getDeviceLogs(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchDeviceStatusAndTypesLogs({ commit }) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getDeviceStatusAndTypesLogs()
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchSitesGrid({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .getSitesGrid(companyId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchSites({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .getSites(companyId)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
              toast.error(err?.data?.message);
            });
        });
      },
      addSite({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .addSite(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editSite({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .editSite(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteSite({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .deleteSite(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      async setSitePassword({ commit }, payload) {
        commit("setLoading", true);
        payload.password = await encryptionPassword(payload.password);
        return new Promise((resolve, reject) => {
          sitesService
            .setSitePassord(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchParentAreas({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          areaService
            .getParentAreas(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchAllAreas({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          areaService
            .getAllAreas(id)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
              toast.error(err?.data?.message);
            });
        });
      },
      addArea({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          areaService
            .addArea(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editArea({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          areaService
            .editArea(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteArea({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          areaService
            .deleteArea(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      moveArea({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          areaService
            .moveArea(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchAppsLibrary({ commit }, compantId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          appsLibraryService
            .getAppsLibrary(compantId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      addApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          appsLibraryService
            .addApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          appsLibraryService
            .editApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          appsLibraryService
            .deleteApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      addDevice({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .addDevice(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editDevice({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .editDevice(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchAllDevices({ commit }, compantId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getAllDevices(compantId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchAppsVersions({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getAppsVersions(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      openDoor({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .openDoor(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      restartDevice({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .restartDevice(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      massRestartDevice({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .massRestartDevice(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      restartApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .restartApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      installApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .installApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      massInstallApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .massInstallApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      uninstallApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .uninstallApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      clearAppData({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .clearAppData(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      clearAppCache({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .clearAppCache(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      forceStopApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .forceStopApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      launchApp({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .launchApp(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteRequestJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .deleteRequestJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      refreshFaceLibrary({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .refreshFaceLibrary(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      moveDevice({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .moveDevice(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      massMoveDevices({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .massMoveDevices(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deregisterDevice({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .deregisterDevice(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      massDeregisterDevices({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .massDeregisterDevices(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      applyConfig({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .applyConfig(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      massApplyConfigs({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .massApplyConfigs(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      applySiteAndAreaConfig({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .applySiteAndAreaConfig(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      updateDeviceSettings({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .updateDeviceSettings(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchScreenshot({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getScreenshot(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchMassScreenshot({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getMassScreenshot(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchDeviceInfo({ commit }, payload) {
        
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getDeviceInfo(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchInstalledApps({ commit }, mac_address) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getInstalledApps(mac_address)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchInstalledAppsGrid({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getInstalledAppsGrid(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchDeviceRemoteControlAction({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .remoteControlNotifyToStart(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              // toast.error(err?.data?.message);
              toast.error("connection not found");
            });
        });
      },
      fetchRoomInfo({ commit }, mac_address) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .getRoomInfo(mac_address)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchDeviceRefreshGetScreen({ commit }, mac_address) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          devicesService
            .refreshGetScreen(mac_address)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      exportSitesGrid({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          sitesService
            .exportSitesGrid(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchUsersGrid({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          userService
            .getUsersGrid(companyId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchUsersRoles({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          userService
            .getUsersRoles(companyId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      addUser({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          userService
            .addUser(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editUser({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          userService
            .editUser(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteUser({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          userService
            .deleteUser(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      checkDuplicateField({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          userService
            .getDuplicateField(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      getCompany({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .getCompany(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      getCompanySettings({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .getCompanySettings(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },      
      updateCompanySettings({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .updateCompanySettings(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editCompany({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .editCompany(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchAllCompanyApps({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .getAllCompanyApps(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchPreInstalledCompanyApps({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .getPreInstalledCompanyApps(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      manageSubscription({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .generateManageSubscriptionUrl(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      generateToken({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .generateToken(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchSubscriptionInfo({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          companyService
            .getSubscriptionInfo(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchConfigsGrid({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          configurationService
            .getConfigs(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      addConfig({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          configurationService
            .addConfig(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editConfig({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          configurationService
            .editConfig(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      getConfigById({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          configurationService
            .getConfigById(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteConfig({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          configurationService
            .deleteConfig(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchUnreadMessagesCounter({ commit }, id) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          inboxService
            .getUnreadMessagesCounter(id)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchMessagesDetails({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          inboxService
            .getMessagesDetails(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchJobsGrid({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .getJobs(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchJobsList({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .getJobsList(companyId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      addJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .addJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .editJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .getJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchJobsActions({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .getJobsActions(companyId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      fetchAllFilesCompany({ commit }, companyId) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .getAllFilesCompany(companyId)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              // toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .deleteJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      moveJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .moveJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      applyJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .applyJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      duplicateJob({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          jobService
            .duplicateJob(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      createFolder({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          folderService
            .createFolder(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      editFolder({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          folderService
            .editFolder(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      deleteFolder({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          folderService
            .deleteFolder(payload)
            .then((res) => {
              resolve(res);
              commit("setLoading", false);
              toast.success(res.message);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err?.data?.message);
            });
        });
      },
      async getScreenAuth({ commit }) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          commit("setLoading", true);
          devicesService
            .getScreenAuth()
            .then(async (data) =>  {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.response.data.message);
            });
        });
      },
      async createConnectionGetScreen({ commit }) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          commit("setLoading", true);
          authService
            .createConnectionGetScreen()
            .then(async (data) =>  {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              toast.error(err.response.data.message);
            });
        });
      },
      async getScreenAgentsList({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          commit("setLoading", true);
          devicesService
            .getScreenAgentsList(payload)
            .then(async (data) =>  {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              // toast.error(err.message);
            });
        });
      },
      async getScreenAgentConnection({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          commit("setLoading", true);
          devicesService
            .getScreenAgentConnection(payload)
            .then(async (data) =>  {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              // toast.error(err.message);
            });
        });
      },
      async getScreenRemoveAgentConnection({ commit }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          commit("setLoading", true);
          devicesService
            .getScreenRemoveAgentConnection(payload)
            .then(async (data) =>  {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
              commit("setLoading", false);
              // toast.error(err.message);
            });
        });
      },
      onTableHeader({ commit, dispatch }, params) {
        commit("setLoading", true);
        // console.log('setHeaders', params);
        return new Promise((resolve, reject) => {
          gridService
            .getTableHeaders(params)
            .then((data) => {
              resolve(data)
              console.log('setHeaders', params, data),
                data.data &&
                  data.data.length > 0
                  //  && Object.keys(JSON.parse(params.headers)).length == Object.keys(JSON.parse(data.data[0].table_head)).length)
                  ?
                  (commit("setTableHeaders", data.data[0].table_head && typeof data.data[0].table_head != 'object' ? JSON.parse(data.data[0].table_head) : data.data[0].table_head ? data.data[0].table_head : null),
                    commit("setTableFilters", data.data[0].url ? data.data[0].url : null),
                    commit("setAdminPreferenceId", data.data[0].id),
                    localStorage.setItem('adminPreferenceId', data.data[0].id),
                    commit("setLoading", false))
                  :
                  (commit("setTableHeaders", null),
                    commit("setTableFilters", null),
                    commit("setAdminPreferenceId", null),
                    commit("setLoading", false))
    
              if (params.url != '/apps-library' && params.url != '/device-logs-page' && params.url != '/devices-logs-page' && params.url != '/device-apps' && params.url != '/sites-and-devices-grid' && params.url != '/users' && params.url != '/configurations' && params.url != '/password-history') {
                if (!data.data[0])
                  dispatch("createTableHeaders", params);
                else if (Object.keys(params.headers).length != (typeof data.data[0].table_head != 'object' ? Object.keys(JSON.parse(data.data[0].table_head)).length : Object.keys(data.data[0].table_head).length))
                  dispatch("createTableHeaders", params);
              }
            })
            .catch((err) => {
              reject(err);
              console.log('err', err);
              err.status != 401 && toast.error(err.data && err.data.message ? err.data.message : "-");
              commit("setLoading", false);
            });
        });
      },
      resetTableHeaders({ commit }) {
        commit("setTableHeaders", {});
      },
      createTableHeaders({ commit }, params) {
        return new Promise((resolve, reject) => {
          gridService
            .createTableHeaders(params)
            .then((data) => {
              resolve(data);
              console.log('createTableHeaders', data);
              commit("setTableHeaders", typeof data.data.table_head != 'object' ? JSON.parse(data.data.table_head) : data.data.table_head);
              commit("setTableFilters", data.data.url);
              commit("setAdminPreferenceId", data.data.id);
              // dispatch("onTableHeader", params);
            })
            .catch((err) => {
              reject(err);
              err.status != 401 && toast.error(err.data.message);
              commit("setLoading", false);
            });
        });
      },
      updateTableHeaders({ commit, dispatch }, params) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
          gridService
            .updateTableHeaders(params)
            .then((data) => {
              resolve(data);
              console.log('updateTableHeaders', data, params);
              commit("setAdminPreferenceId", data.data.id);
              dispatch("onTableHeader", params);
              commit("setLoading", false);
              // if (params.url !== "/")
              // this._vm.$toast.success("Table View Updated");
            })
            .catch((err) => {
              console.log('errrrrrrrrrrr', err)
              reject(err);
              commit("setLoading", false);
              err.status != 401 && toast.error(err.message);
            });
        });
      },
      fetchModalData({ commit }, data){
        commit("setModalData", data);
      },
      fetchModalAction({ commit }, data){
        commit("setModalAction", data);
      },
      updateCollapseState({commit},data){
        console.log({data})
        commit("setCollapseSate",data)
      },
      fetchDeviceDataObject({ commit }, data){
        commit("deviceDataObject", data);
      },
    }
});

export default store;