<script setup>
    import { ref, defineProps, onMounted, reactive } from "vue";
    import { Modal }  from "bootstrap";
    // import { useStore } from "vuex";
    import axios from "axios";

    // const store = useStore();
    const props = defineProps({
        data: Object,
    });

    let items = ref([]);
    // const emit = defineEmits(['refreshGrid']);

    const state = reactive({
        deleteRequestJobModal: null,
    });

    // const resetStoreData = () =>{
    //     setTimeout(() => {
    //         store.commit('setModalState', false);
    //         store.commit('setModalData', {});
    //         store.commit('setModalAction', null);
    //     },200)
    // };

    const action = async (action, data) => {
        // resetStoreData();
        // console.log('data', data);
        // await store.commit('setModalAction', action);
        // store.commit('setModalState', true);
        // await store.commit('setModalData', data);

        if(action == 'download_screenshot'){
            axios.get(data.downloadable_file, { responseType: 'blob' })
            .then(response => {
                var str = data.downloadable_file;
                var n = str.lastIndexOf('/');
                var label = str.substring(n + 1);
                const blob = new Blob([response.data], { type: 'application/jpg' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `${data.device_name}_screenshot_${label}`;
                link.click();
                URL.revokeObjectURL(link.href);
                // emit('refreshGrid');
                // resetStoreData();
            }).catch(console.error)
        }else if(action == 'delete_job_request'){
            state.deleteRequestJobModal = new Modal("#deleteRequestJobModal", {});
            state.deleteRequestJobModal.show();
        }
    }

    onMounted(() => {
        items.value.push(
            { 
                icon : 'bi bi-download',
                text: "Download", 
                action: "download_screenshot",
                visible: props.data.downloadable_file,
                data : props.data,
            },
            // {
            //     icon : 'bi bi-trash3',
            //     text: "Delete Job",
            //     action: "delete_job_request",
            //     visible:  props.data.status == 'placed' ||  props.data.status == 'failed' ? true : false,
            //     data : props.data,
            // },
        );
    });

</script>

<template>
    <div>
        <button v-for="(item, index) in items" :key="index" v-show="item.visible" @click="action(item.action, props.data)" type="button" :class="item.customStyle ? 'mt-1' :''" class="btn action-btn" :title="item.text"><i :class="item.icon"></i> </button>
    </div>
</template>