import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "bootstrap-icons/font/bootstrap-icons.css";
// import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
// import 'devextreme/dist/css/dx.light.css'
import 'devextreme/dist/css/dx.material.blue.light.compact.css'; // dx.light.css
import './assets/css/main.css'
import './assets/scss/helpers.scss'
import './assets/css/customStyles.css'
import './assets/css/grids.css'
import './assets/css/modals.css'
import './assets/css/multiselect.css'
import './assets/fonts/Lato/LatoFonts.css';
import 'primevue/resources/themes/lara-light-teal/theme.css';
import 'primeicons/primeicons.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import '@vuepic/vue-datepicker/dist/main.css'
import "vue-toastification/dist/index.css";
import 'vue-skeletor/dist/vue-skeletor.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueTelInput from 'vue-tel-input';
import { Skeletor } from 'vue-skeletor';
import 'vue-tel-input/vue-tel-input.css';
// import BootstrapVueNext from 'bootstrap-vue-next'
import { Popover } from 'bootstrap'

const app = createApp(App);

// import PrimeVue from 'primevue/config';
// import InputText from 'primevue/inputtext';
// import ProgressSpinner from 'primevue/progressspinner';
// import Dropdown from 'primevue/dropdown';
// import Button from 'primevue/button';
// import DMultiselect from "./components/shared/DMultiselect.vue"
import DModal from '@/components/shared/DModal.vue';
import Multiselect from "vue-multiselect";
import VueDatePicker from '@vuepic/vue-datepicker';
// import VueGoogleMaps from '@fawmi/vue-google-maps';

import Toast from 'vue-toastification'

const user = JSON.parse(localStorage.getItem('userDetails'));
// const compantId = localStorage.getItem("currentCompanyId");

const globalOptions = {
    mode: 'auto',
    inputOptions : {
        placeholder : 'Phone Number (Optional)',
        // showDialCode : true,
    },
    customValidate : /^\d*$/,
    dropdownOptions : {
        showSearchBox : true,
    },
    validCharactersOnly : true,
  };


new Popover(document.body, {selector: "[data-bs-toggle='popover']"})
// app.provide('global_user_id', user?.id);
app.provide('global_user_name', user?.first_name +' '+ user?.last_name);
// app.provide('global_company_id', compantId);

app.component('DModal', DModal);
// app.component('PInput', InputText);
// app.component('PButton', Button);
// app.component('PDropdown', Dropdown);
// app.component('ProgressSpinner', ProgressSpinner);

//Form components
app.component('VMultiselect', Multiselect);
// app.component('DMultiselect', DMultiselect);
app.component('VueDatePicker', VueDatePicker);
app.component(Skeletor.name, Skeletor);

app.use(Toast);
// app.use(PrimeVue);
app.use(BootstrapVue3);
app.use(VueTelInput, globalOptions);

// app.use(BootstrapVueNext)
app.use(router);
app.use(store);
app.mount('#app');
