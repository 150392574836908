<script setup>
  import { ref, defineProps, defineEmits } from "vue";
  // import { useStore } from "vuex";
  const props = defineProps({
    closeModal: Function,
  });
  const emit = defineEmits(['refresh']);
  // const store = useStore();
  import { useToast } from "vue-toastification";    

  const toast = useToast();

  const isFileUploaded = ref(false);
  const company_id = localStorage.getItem('currentCompanyId');
  let isValidFile = ref(false);
  let isSubmitted = ref(false);
  let appName = ref(null);
  let fileName = ref(null);
  let appFile = ref(null);
  let fileError = ref(false)
  const apkFile = ref(null);
  let uploadPercentage = ref(0);
  const user = JSON.parse(localStorage.getItem("user"));
  import axios from 'axios';

  let headers = {
    "Accept": "application/json",
    "Content-type": "multipart/form-data",
    "Authorization" : "Bearer "+user.access_token,
  }

  const uploadFile = async (e)=>{
    fileError.value = false;
    let selectedFile = e.target.files[0];
    uploadPercentage.value = 0;
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      // console.log('selectedFile.size', selectedFile.size);
      if ( selectedFile.size > 157286400 ) {
        fileError.value = true;
        return 
      }else if (selectedFile && (selectedFile.type == 'application/vnd.android.package-archive' || selectedFile.type == 'application/octet-stream')) {
        fileName.value = selectedFile.name;
        apkFile.value = selectedFile;
        isFileUploaded.value = true;
      }
    };
    reader.onerror = (evt) => {
      console.error(evt);
    };
  }

  const resetUploadedFileData = () => {
    isFileUploaded.value = false;
    isValidFile.value = null
    appName.value = null;
    fileName.value = null;
    appFile.value.value = null;
    fileError.value = false;
  };

  const onSubmit = () => {

    isSubmitted.value = true;
    let formData = new FormData();
    formData.append("name", appName.value);
    formData.append("file", apkFile.value);
    formData.append("company_id", company_id);
    
    // store.dispatch("addApp", formData)
    // .then((res) => {
    //   isSubmitted.value = false;
    //   console.log('res', res);
    //   resetUploadedFileData();
    //   props.closeModal();
    //   emit('refresh');
    // })
    // .catch((e) => {
    //   console.log("e", e);
    //   isSubmitted.value = false;
    // });

    axios.post(`${process.env.VUE_APP_BASE_URL}/api/app-upload`, formData, {
      headers: headers,
      onUploadProgress: function( progressEvent ) {
        uploadPercentage.value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) 
      )}.bind(this)
    }).then((res) => {
      toast.success(res.data.message);
      isSubmitted.value = false;
      resetUploadedFileData();
      props.closeModal();
      emit('refresh');
    })
    .catch((e) => {
      console.log('e', e);
      toast.error(e.message);
      isSubmitted.value = false;
    });

  };
  
</script>

<template>
  <DModal id="appModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Add App </template>
    <template #body>

      <div class="form-floating mb-3">
        <input type="text" v-model.trim="appName" class="form-control" id="appName" placeholder=""/>
        <label for="appName">App Name</label>
      </div>

      <div class="container" :class="{'disabled-dev' : fileName && appFile, 'is-invalid' : fileError}" @click="$refs.appFile.click()">
        <div class="icon">
          <input ref="appFile" class="form-control form-control-lg d-none"
            type="file" @change="uploadFile" :disabled="fileName && appFile" accept=".apk"
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">
            <path fill="#787878"
              d="M11 0C5.22112 0 0.5 4.69532 0.5 10.5C0.5 15.0921 3.44102 18.9877 7.54298 20.4066V17.027C5.19527 15.7888 3.59582 13.312 3.59582 10.4742C3.59582 6.39807 6.92373 3.07006 11 3.07006C15.0761 3.07006 18.4041 6.39797 18.4041 10.4742C18.4041 13.3379 16.7788 15.8403 14.3796 17.0528V20.4067C18.5075 18.9877 21.5 15.0664 21.5 10.4743C21.5004 4.69541 16.7793 9.21307e-05 11.0005 9.21307e-05L11 0Z"
            />
            <path fill="#787878"
              d="M15.6981 11.8135C16.2914 11.2202 16.2914 10.2398 15.6981 9.62058L12.1121 6.03459C11.8026 5.72507 11.4155 5.57031 11.0286 5.57031C10.6416 5.57031 10.2289 5.72508 9.94504 6.03459L6.35905 9.62058C5.76573 10.2139 5.76573 11.1943 6.35905 11.8135C6.95237 12.4069 7.93276 12.4069 8.55201 11.8135L9.48076 10.8848V22.9585C9.48076 23.8098 10.1774 24.5065 11.0288 24.5065C11.8801 24.5065 12.5768 23.8099 12.5768 22.9585V10.8332L13.5571 11.8136C14.0985 12.4069 15.0788 12.4069 15.6981 11.8135Z"
            />
          </svg>
          <div class="add-files-text ml-16">Add Apk</div>
        </div>
      </div>

      <div v-if="fileName && appFile && !fileError" class="container success-container">
        <div class="d-flex align-items-center justify-content-between" style="width: 416px!important;">
          <div class="d-flex  padding-0">
            <svg  xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18 17"
              fill="none" style="width: 17px; height: 17px; flex-shrink: 0">
              <circle opacity="0.94" cx="9" cy="8.5" r="8.5" fill="#34C38F" />
              <path fill="white"
                d="M13.4765 5.6447C13.377 5.56806 13.263 5.51125 13.1411 5.4775C13.0192 5.44375 12.8917 5.43373 12.766 5.44801C12.6403 5.46229 12.5188 5.5006 12.4084 5.56074C12.298 5.62087 12.201 5.70167 12.1228 5.7985L8.17196 10.6829L5.86373 8.94634C5.67861 8.80689 5.44452 8.74467 5.21296 8.77336C4.98139 8.80205 4.77132 8.91932 4.62895 9.09935C4.48659 9.27938 4.42359 9.50744 4.45382 9.73335C4.48405 9.95926 4.60504 10.1645 4.79015 10.304L7.88422 12.6315C7.97952 12.7032 8.08888 12.7549 8.20548 12.7835C8.32207 12.8122 8.44337 12.817 8.56181 12.7978C8.68777 12.7838 8.80956 12.7456 8.9202 12.6855C9.03084 12.6254 9.12814 12.5445 9.20654 12.4476L13.6391 6.96608C13.7971 6.77033 13.8687 6.52115 13.8382 6.2733C13.8077 6.02544 13.6776 5.79919 13.4764 5.64427"
              />
            </svg>
            <div class="filename-text ml-16">{{ fileName }}</div>
          </div>
          <div class=" padding-0">
            <button :disabled="isSubmitted" @click="resetUploadedFileData" class="remove-file-button">Remove</button>
          </div>
        </div>
      </div>
      <span class="hint-input" :class="{'hint-input-error' : fileError }"><b>Note:</b> Uploaded file size should be 150MB or less.</span>  
      <b-progress class="mt-20" v-if="isSubmitted" height="1rem" :max='100' striped animated>
        <b-progress-bar :value="uploadPercentage" variant="success" :label="`${uploadPercentage}%`"></b-progress-bar>
      </b-progress>
    </template>

    <template #footer>

      <div class="w-100">
        <div class="seperator text-center" style="margin-top: 26px!important;"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!isFileUploaded || !appName || fileError || isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">Add 
            <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          <button @click="resetUploadedFileData()" :disabled="isSubmitted" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal"> Cancel </button>
        </div>
      </div>

    </template>
  </DModal>
</template>

<style scoped>
  .container, .success-container {
    width: 416px!important;
    height: 55px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }

  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon svg {
    width: 21px;
    height: 24.509px;
    flex-shrink: 0;
  }

  .filename-text {
    color: #2D8425;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px; /* 106.25% */
    letter-spacing: 0.25px;
  }

  .add-files-text {
    color: var(--787878, #787878);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px; /* 106.25% */
    letter-spacing: 0.25px;
  }

  .success-container svg {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
  }

  .success-container .text {
    margin-left: 16px;
  }

  .remove-file-button {
    color: var(--f-46-a-6-a, #f46a6a);
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.25px;
    cursor: pointer;
    border: none;
    background: transparent;
    &:disabled{
        opacity: 0.4!important;
    }
  }
  .ml-16{
    margin-left: 16px!important;
  }
  .filename-text.ml-16 {
    /* width: 200px; */
    width: 250px!important;
    /* overflow: hidden; */
    /* white-space: break-spaces; */
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
